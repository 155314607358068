import React from "react"
import { useSelector } from "react-redux"
import Helmet from "react-helmet"

import Layout from "../../components/layout"
import { useHomePageData, RegisterDemoAccount } from "../../prismic/staticQueries"

import TradingPlatforms from "./trading-platforms"
import CarouselTop from "./carousel"

const IndexPage = () => {
  const language = useSelector(state => state.language)

  const homePageData = useHomePageData(language)
  const registerDemoAccount = RegisterDemoAccount(language)

  return (
    <Layout>
      <>
      <Helmet>
          <script>
            {`(function(h,e,a,t,m,p) {
            m=e.createElement(a);m.async=!0;m.src=t;
            p=e.getElementsByTagName(a)[0];p.parentNode.insertBefore(m,p);
            })(window,document,'script','https://u.heatmap.it/log.js');`}
          </script>
        </Helmet>
        <CarouselTop {...registerDemoAccount} language={language} />
        <TradingPlatforms {...homePageData} {...registerDemoAccount} lang={language} />
      </>
    </Layout>
  )
}

export default IndexPage
